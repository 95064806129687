import { HomePageSection } from '@components/Atoms/Containers';
import {
  ScrollingSection,
  InfiniteList,
  Card,
  Avatar,
  Button,
  IconCard,
} from '@teddly/teddly-ui-components';
import useWindowDimensions from '@hooks/useWindowDimension';
import styles from './HomePage.module.scss';
import classNames from 'classnames';
import { useRoutesContext } from '@context/RoutesContext';
import React from 'react';
import {
  ProductsListWidget,
  SortProductIndex,
} from '@app/products/ProductListWidget';
import {
  CardImagePosition,
  HomePageSectionsQuery,
  ScrollableItemSize,
  ScrollableItemType,
  useAuth,
  useHomePageSections,
} from 'teddly-sdk';
import {
  HomePageSections_homePageSections_edges_node,
  HomePageSections_homePageSections_edges_node_bannerSection,
  HomePageSections_homePageSections_edges_node_scrollableSection,
  HomePageSections_homePageSections_edges_node_scrollableSection_items,
} from 'teddly-sdk/lib/queries/gqlTypes/HomePageSections';
import { useHomePageContext } from '@context/HomePageContext';
import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import { usePageLayoutContext } from '@context/PageLayoutContext';
import { useChannelContext } from '@context/ChannelContext';

type SectionType =
  HomePageSectionsQuery['homePageSections']['edges'][0]['node'];

type Props = {
  screenSize: number;
  marginByBackground: number;
  selectStateTypeButtons: JSX.Element;
};

function HomePageSections({
  marginByBackground,
  screenSize,
  selectStateTypeButtons,
}: Props): JSX.Element {
  const { isLargeTablet } = useWindowDimensions();
  const { navigateTo } = useRoutesContext();
  const { stateType, data, loadMore, loading } = useHomePageContext();
  const { authenticated } = useAuth();

  const getSectionIndexInData = (section: SectionType) => {
    return data?.edges?.indexOf(
      data?.edges?.find((node) => node?.node?.id === section?.id),
    );
  };

  const isOtherProductsSectionInLastRequest = (
    section: SectionType,
  ): boolean => {
    const sectionIndexInData = getSectionIndexInData(section);
    const lastRequestSections = data?.edges?.slice(
      sectionIndexInData,
      data?.edges?.length,
    );
    const results = lastRequestSections?.find((node) => {
      const nodeSection = node?.node;
      return (
        nodeSection?.scrollableSection?.itemsType ===
          ScrollableItemType.PRODUCT && nodeSection?.id !== section?.id
      );
    });
    return !!results;
  };
  const getScrollableCardItemPosition = (
    section: HomePageSections_homePageSections_edges_node_scrollableSection | null,
  ) =>
    isLargeTablet
      ? CardImagePosition.TOP
      : section?.cardImagePosition || CardImagePosition.RIGHT;

  const getCardPositionForBanner = () =>
    isLargeTablet ? CardImagePosition.TOP : CardImagePosition.RIGHT;

  const isHorizontalCard = (position: CardImagePosition) =>
    position === CardImagePosition.RIGHT || position === CardImagePosition.LEFT;

  const getOnClickUrl = (url: string) =>
    url?.startsWith('/')
      ? navigateTo({ route: `${url}`, options: { scroll: false } })
      : (window.location.href = url);

  const onItemCardButtonClicked = (
    event: React.MouseEvent<HTMLButtonElement>,
    url: string,
  ) => {
    event.stopPropagation();
    getOnClickUrl(url);
  };

  const getBannerSectionImageUrl = (
    banner: HomePageSections_homePageSections_edges_node_bannerSection,
  ) =>
    isLargeTablet
      ? banner?.mobileBackgroundImage?.url
      : banner?.desktopBackgroundImage?.url;

  const getBannerSection = (
    banner: HomePageSections_homePageSections_edges_node_bannerSection,
    isFirst: boolean,
  ): EmotionJSX.Element => (
    <Card
      key={banner?.id}
      imageClassName={classNames(
        getBannerSectionImageUrl(banner) && styles.isWithBackgroundImage,
      )}
      clickable={!banner.disabled}
      className={classNames(styles.banner, isFirst && styles.isFirst)}
      stretch
      disabled={banner?.disabled}
      imageSrc={getBannerSectionImageUrl(banner)}
      imagePosition={getCardPositionForBanner()?.toLowerCase()}
      onClick={() => getOnClickUrl(banner?.onClickUrl)}
      title={banner?.title}
      subTitle={banner?.subTitle}
    />
  );

  const getCardItem = (
    section: HomePageSections_homePageSections_edges_node,
    item: HomePageSections_homePageSections_edges_node_scrollableSection_items,
  ): EmotionJSX.Element => {
    const isShowButton = item?.onClickUrl && item?.cardButtonTitle;
    return (
      <Card
        className={
          isHorizontalCard(
            getScrollableCardItemPosition(section?.scrollableSection),
          )
            ? styles.horizontalCard
            : !isLargeTablet
              ? styles.desktopVerticalCard
              : styles.mobileVerticalCard
        }
        // imageClassName={styles.horizontalCardImage}
        imageClassName={classNames(
          item?.backgroundImage?.url && styles.isWithBackgroundImage,
        )}
        key={item?.id}
        disabled={item?.disabled}
        // titleClassName={styles.cardInScrollingSection}
        clickable={item?.isClickable && !isShowButton}
        stretch={false}
        title={item?.title}
        subTitle={item?.subTitle}
        onClick={() => getOnClickUrl(item?.onClickUrl)}
        imageSrc={!isShowButton ? item?.backgroundImage?.url : null}
        imagePosition={getScrollableCardItemPosition(
          section?.scrollableSection,
        )?.toLowerCase()}
        showButton={isShowButton}
        button={
          isShowButton && (
            <Button
              size={'medium'}
              variant={'fill'}
              onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
                onItemCardButtonClicked(event, item?.onClickUrl)
              }
              title={item.cardButtonTitle}
            />
          )
        }
      />
    );
  };

  const getAvatarItem = (
    section: HomePageSections_homePageSections_edges_node,
    item: HomePageSections_homePageSections_edges_node_scrollableSection_items,
  ) => {
    return (
      <Avatar
        key={item?.id}
        disabled={item?.disabled}
        imageSrc={
          section?.scrollableSection?.itemsType === ScrollableItemType.AVATAR
            ? item?.backgroundImage?.url
            : null
        }
        name={item?.title}
        iconUrl={item?.avatarIcon?.url}
        iconAlt={item?.avatarIcon?.alt}
        isClickable={item?.isClickable}
        size={
          section?.scrollableSection?.avatarSize?.toLowerCase() ||
          ScrollableItemSize.MEDIUM.toLowerCase()
        }
        title={item?.title}
        isLogo={item?.isLogo}
        onClick={() => getOnClickUrl(item?.onClickUrl)}
      />
    );
  };

  const getIconCardItem = (
    section: HomePageSections_homePageSections_edges_node,
    item: HomePageSections_homePageSections_edges_node_scrollableSection_items,
  ) => {
    return (
      <IconCard
        key={item?.id}
        disabled={item?.disabled}
        isClickable={item?.isClickable}
        // imageSrc={section?.scrollableSection?.itemsType === ScrollableItemType.AVATAR ? item?.backgroundImage?.url : null}
        name={item?.title}
        iconUrl={item?.avatarIcon?.url}
        iconAlt={item?.avatarIcon?.alt}
        size={
          section?.scrollableSection?.avatarSize === ScrollableItemSize.LARGE
            ? ScrollableItemSize.LARGE.toLowerCase()
            : 'default'
        }
        // title={item?.title}
        onClick={() => getOnClickUrl(item?.onClickUrl)}
      />
    );
  };

  const getScrollableSectionSortProductsIndex = (
    section: HomePageSections_homePageSections_edges_node,
  ): SortProductIndex[] => {
    const sortProductsIndex: SortProductIndex[] = [];
    section?.scrollableSection?.items?.forEach((item, index) => {
      if (item?.product?.id || item?.productVariant?.product?.id) {
        const itemForSort: SortProductIndex = {
          id: item?.product?.id
            ? item.product.id
            : item?.productVariant?.product?.id || '',
          index: index,
        };
        sortProductsIndex.push(itemForSort);
      }
    });
    return sortProductsIndex;
  };
  const getProductsItemsList = (
    section: HomePageSections_homePageSections_edges_node,
  ): EmotionJSX.Element => {
    {
      const isOtherProductsSectionInRequest =
        isOtherProductsSectionInLastRequest(section);
      return (
        <ProductsListWidget
          waitLoadingForCategories={isOtherProductsSectionInRequest}
          includeOutOfStock={false}
          sortProductsIndex={
            !section?.scrollableSection?.isAllVendors || authenticated
              ? getScrollableSectionSortProductsIndex(section)
              : null
          }
          returnAsSimpleList
          hitsPerPage={40}
          itemClassName={styles.itemClassName}
          ids={
            section?.scrollableSection?.items
              ?.filter((item) => item?.product)
              ?.map((item) => item?.product?.id) || []
          }
          variantsIds={section?.scrollableSection?.items
            ?.filter((item) => item?.productVariant)
            ?.map((item) => item?.productVariant?.id)}
        />
      );
    }
  };

  const getItems = (section: HomePageSections_homePageSections_edges_node) => {
    const itemType: ScrollableItemType | null = section.scrollableSection
      ?.itemsType
      ? section.scrollableSection?.itemsType
      : null;
    switch (itemType) {
      case ScrollableItemType.PRODUCT:
        return getProductsItemsList(section);
      case ScrollableItemType.CARD:
        return section?.scrollableSection?.items?.map((item) =>
          item ? getCardItem(section, item) : null,
        );
      case ScrollableItemType.AVATAR:
        return section?.scrollableSection?.items?.map((item) =>
          item ? getAvatarItem(section, item) : null,
        );
      case ScrollableItemType.ICON_CARD:
        return section?.scrollableSection?.items?.map((item) =>
          item ? getIconCardItem(section, item) : null,
        );
    }
  };

  const getScrollableSection = (
    section: HomePageSections_homePageSections_edges_node,
    isFirst: boolean,
  ) => (
    <ScrollingSection
      key={section?.id}
      id={section?.id}
      screenSize={screenSize}
      isScrollingByWheel={isLargeTablet}
      titleIcon={
        section?.scrollableSection?.iconTitle ? (
          <img
            alt={section?.scrollableSection?.iconTitle?.alt || ''}
            src={section?.scrollableSection?.iconTitle?.url}
          />
        ) : null
      }
      sidePullSize={marginByBackground}
      isTowLines={
        isLargeTablet &&
        section?.scrollableSection?.itemsType === ScrollableItemType.ICON_CARD
      }
      itemsClass={styles.cardsClass}
      title={section?.scrollableSection?.title}
      subTitle={section?.scrollableSection?.subTitle}
      backgroundColor={section?.scrollableSection?.backgroundColor}
      generalClassName={classNames(
        styles.scrollingSectionWithBackground,
        styles.generalScrollingSectionClass,
        isFirst && styles.isFirst,
      )}
      items={getItems(section)}
    />
  );

  const getSection = (
    section: HomePageSections_homePageSections_edges_node,
    index: number,
  ) => {
    return section?.bannerSection
      ? getBannerSection(section?.bannerSection, index === 0)
      : getScrollableSection(section, index === 0);
  };

  return (
    <HomePageSection id={'home-page-section'}>
      {isLargeTablet && selectStateTypeButtons}
      {data?.edges?.length && (
        <InfiniteList
          isWrap={false}
          className={styles.homePageSectionContainers}
          items={data?.edges?.map((node) => node?.node)}
          loadMoreItems={loadMore}
          totalItems={data?.totalCount}
          rowRender={getSection}
          isLoading={loading}
          clickToLoad={false}
        />
      )}
    </HomePageSection>
  );
}

export default HomePageSections;
